import React, { useEffect, useRef, useState } from "react";
import "../css/style.css";
import "../css/mobile.css";
import "../css/bootstrap.min.css";
import emailjs from "@emailjs/browser";
import logoK from "../../assets/LOGO-K.svg";
import gkn from "../../assets/teams-img1.svg";
import ysn from "../../assets/teams-img2.svg";
import ayfr from "../../assets/teams-img3.svg";
import whoWeAre from "../../assets/who-we-right.png";
import headerRightImage from "../../assets/header-right-img.png";
import Button from "../../components/button";
import { useDispatch, useSelector } from "react-redux";
import TermsOfUse from "../../components/termsOfUse";
import PrivacyPolicy from "../../components/privacyPolicy";
import {
	togglePrivacyPolicyVisibility,
	toggleTermsOfUseModalVisibility,
} from "../../redux/features/ui-slice";

function LandingPage() {
	const form = useRef();

	const dispatch = useDispatch();
	const toggleTermsOfUseModal = () =>
		dispatch(toggleTermsOfUseModalVisibility());
	const togglePrivacyPolicy = () => dispatch(togglePrivacyPolicyVisibility());
	// 0 -  not send 1 - Sending 2- Sent

	const { termsOfUseModalVisibile, privacyPolicyModalVisible } = useSelector(
		(state) => state.uiReducer
	);
	const [contactFormStatus, setContactFormStatus] = useState(0);
	const [formValues, setFormValues] = useState({
		from_name: "",
		from_email: "",
		from_phone: "",
		message: "",
		error: false,
	});

	console.log(termsOfUseModalVisibile, privacyPolicyModalVisible);

	useEffect(() => {
		if (contactFormStatus === 2) {
			setTimeout(() => {
				setFormValues({
					from_name: "",
					from_email: "",
					from_phone: "",
					message: "",
					error: false,
				});
				setContactFormStatus(0);
				clearTimeout();
			}, 2000);
		}
	}, [contactFormStatus]);

	useEffect(() => {
		if (
			formValues.from_email &&
			formValues.from_name &&
			formValues.message &&
			formValues.from_phone
		) {
			setFormValues({ ...formValues, error: false });
		}
	}, [formValues]);
	const sendEmail = (e) => {
		const { from_name, from_email, from_phone, message } = formValues;
		e.preventDefault();

		if (from_email && from_name && from_phone && message) {
			setContactFormStatus(1);
			emailjs
				.sendForm("service_k1gx7qp", "template_ya26wmb", form.current, {
					publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
				})
				.then(
					() => {
						setContactFormStatus(2);
						setFormValues({
							from_name: "",
							from_email: "",
							from_phone: "",
							message: "",
							error: false,
						});
					},
					(error) => {
						setContactFormStatus(0);
						console.log(error);
					}
				);
		} else {
			setFormValues({ ...formValues, error: true });
		}
	};
	return (
		<>
			{/* <!-- HEADER-SECTION --> */}
			<div className='home-header-section'>
				<figure className='banner-right-img left_icon img'>
					<img src={headerRightImage} alt='' className='star' />
				</figure>
				<header className='header'>
					<div className='main-header'>
						<div className='container-fluid'>
							<nav className='navbar navbar-expand-lg navbar-light p-0'>
								{/* <a className="navbar-brand pt-0" href="index.html"><img src="./assets/images/banner-logo.svg" alt="" className="img-fluid diverge-logo"></a>  */}
								<button
									className='navbar-toggler collapsed'
									type='button'
									data-toggle='collapse'
									data-target='#navbarSupportedContent'
									aria-controls='navbarSupportedContent'
									aria-expanded='false'
									aria-label='Toggle navigation'>
									<span className='navbar-toggler-icon'></span>
									<span className='navbar-toggler-icon'></span>
									<span className='navbar-toggler-icon'></span>
								</button>
								<div
									className='collapse navbar-collapse'
									id='navbarSupportedContent'>
									<ul className='navbar-nav'>
										<li className='nav-item active'>
											<a
												className='nav-link text-decoration-none navbar-text-color home-margin-top'
												href='/'>
												Home<span className='sr-only'>(current)</span>
											</a>
										</li>
										<li className='nav-item'>
											<a
												className='nav-link text-decoration-none navbar-text-color'
												href='#us'>
												About us
											</a>
										</li>
										<li className='nav-item'>
											<a
												className='nav-link text-decoration-none navbar-text-color'
												href='/login'>
												Services
											</a>
										</li>
									</ul>
									<div className='btn-talk ml-auto'>
										<ul className='m-0 p-0'>
											<li className='list-unstyled d-lg-inline-block'>
												<a className='nav-link contact' href='#contact'>
													Contact us
												</a>
											</li>
										</ul>
									</div>
								</div>
							</nav>
						</div>
					</div>
				</header>
				<div className='home-banner-section overflow-hidden position-relative'>
					<div className='banner-container-box'>
						<div className='container-fluid'>
							<div className='row'>
								<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-md-0 mb-4 text-md-left text-center order-lg-1 order-2'>
									<div className='social-icons position-absolute'>
										<ul className='list-unstyled'>
											<li>
												<a className='text-decoration-none'>
													<i className='fa-brands fa-facebook-f social-networks'></i>
												</a>
											</li>
											<li>
												<a className='text-decoration-none'>
													<i className='fa-brands fa-twitter social-networks'></i>
												</a>
											</li>
											<li>
												<a className='text-decoration-none'>
													<i className='fa-brands fa-instagram social-networks'></i>
												</a>
											</li>
										</ul>
									</div>
									<div
										className='home-banner-text'
										data-aos='fade-up'
										id='myContentDIV'>
										<h1>
											<span className='h1-text'> Emotion AI</span> Powered Video
											Conferencing
										</h1>
										<p className='banner-paragraph'>
											We see the participants in your meetings and assist you
											during the meeting.
										</p>
										<div className='banner-btn discover-btn-banner'>
											<a href='/login' className='text-decoration-none'>
												Try Now
											</a>
											{/* <a href="about.html" className="text-decoration-none play-button">Contact us</a> */}
										</div>
									</div>
								</div>
								<div className='col-xl-6 col-lg-5 col-md-12 col-sm-12 order-lg-2 order-1'>
									<div className='banner-img-content position-relative'>
										<img
											className='img-fluid banner-img-width'
											src={logoK}
											alt='seenmood'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section
				className='our-teams-section overflow-hidden position-relative'
				id='us'>
				<figure className='who-we-right-fig'>
					<img src={whoWeAre} alt='' className='star' />
				</figure>
				<div className='container'>
					<div className='row'>
						<div className='col-xl-6 col-lg-5 col-md-12 col-sm-12 order-lg-1 order-2'>
							<div
								className='about-us-content aos-init aos-animate'
								data-aos='fade-up'>
								<h6 className='autorix-text' data-aos='fade-up'>
									Our team
								</h6>
								<h2 data-aos='fade-up-right' className='aos-init aos-animate'>
									The Expert Team Behind the Video Conferencing Tool
								</h2>
								<p className='aboutus-p'>
									Facial expressions have aroused interest in the scientific
									community since the late 1800s. Famous neurologist Guillaume
									Duchenne and geologist/biologist Charles Darwin are among the
									first scientists to research the subject.
								</p>
								<p className='aboutus-p our-teamp2'>
									Our goal is to combine this knowledge with technology and move
									it forward.
								</p>
								<div className='banner-btn discover-btn-banner'>
									<a href='#contact' className='text-decoration-none'>
										Join Our Team
									</a>
								</div>
							</div>
						</div>
						<div className='col-xl-6 col-lg-7 col-md-12 col-sm-12 order-lg-2 order-1'>
							<div className='row'>
								<div className='col-lg-6 col-md-6 col-sm-6'>
									<figure className='teams-fig'>
										<img src={gkn} alt='' />
									</figure>
									<div className='teams-img-content'>
										<div className='hover_box_plus'>
											<ul className='p-0 text-center'>
												<li>
													<a href='#' className='text-decoration-none'>
														<i className='fa-brands fa-facebook-f'></i>
													</a>
												</li>
												<li>
													<a href='#' className='text-decoration-none'>
														<i className='fa-brands fa-twitter'></i>
													</a>
												</li>
												<li>
													<a href='#' className='text-decoration-none'>
														<i className='fa-brands fa-instagram'></i>
													</a>
												</li>
											</ul>
										</div>
										<div className='teams-text'>
											<h5>Gökhan KIZILIŞIKOĞLU</h5>
											<p>CO-FOUNDER</p>
										</div>
									</div>
								</div>
								<div className='col-lg-6 col-md-6 col-sm-6'>
									<figure className='teams-fig'>
										<img src={ysn} alt='' />
									</figure>
									<div className='teams-img-content'>
										<div className='hover_box_plus'>
											<ul className='p-0 text-center'>
												<li>
													<a href='#' className='text-decoration-none'>
														<i className='fa-brands fa-facebook-f'></i>
													</a>
												</li>
												<li>
													<a href='#' className='text-decoration-none'>
														<i className='fa-brands fa-twitter'></i>
													</a>
												</li>
												<li>
													<a href='#' className='text-decoration-none'>
														<i className='fa-brands fa-instagram'></i>
													</a>
												</li>
											</ul>
										</div>
										<div className='teams-text'>
											<h5>Yasin ULUSOY</h5>
											<p>CO-FOUNDER</p>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-lg-6 col-md-6 col-sm-6'>
									<figure className='teams-fig'>
										<img src={ayfr} alt='' />
									</figure>
									<div className='teams-img-content'>
										<div className='hover_box_plus'>
											<ul className='p-0 text-center'>
												<li>
													<a href='#' className='text-decoration-none'>
														<i className='fa-brands fa-facebook-f'></i>
													</a>
												</li>
												<li>
													<a href='#' className='text-decoration-none'>
														<i className='fa-brands fa-twitter'></i>
													</a>
												</li>
												<li>
													<a href='#' className='text-decoration-none'>
														<i className='fa-brands fa-instagram'></i>
													</a>
												</li>
											</ul>
										</div>
										<div className='teams-text'>
											<h5>Prof. Dr. Ayfer ALPER</h5>
											<p>ACADEMIC ADVISOR</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='contact-us-form' id='contact'>
				<figure className='about-left-back-img'>
					<img
						src='./assets/images/contact-before-img.png'
						alt=''
						className='star'
					/>
				</figure>
				<div className='container text-center'>
					<div className='row'>
						<div className='col-12'>
							<h2
								className='text-center aos-init aos-animate'
								data-aos='fade-left'>
								Send us a message
							</h2>
						</div>
					</div>
					<form ref={form} id='form_id' method='post' onSubmit={sendEmail}>
						<div className='contact-us-wrapper'>
							<div className='row'>
								<div className='col-lg-4 col-md-4 col-sm-12'>
									<div className='col-inner'>
										<input
											type='text'
											name='from_name'
											className='form-control input-fill'
											placeholder='Name'
											onChange={(e) =>
												setFormValues({
													...formValues,
													from_name: e.target.value,
												})
											}
										/>
									</div>
								</div>
								<div className='col-lg-4 col-md-4 col-sm-12'>
									<div className='col-inner'>
										<input
											type='text'
											name='from_phone'
											className='form-control input-fill'
											placeholder='Phone'
											onChange={(e) =>
												setFormValues({
													...formValues,
													from_phone: e.target.value,
												})
											}
										/>
									</div>
								</div>
								<div className='col-lg-4 col-md-4 col-sm-12'>
									<div className='col-inner'>
										<input
											type='text'
											name='from_email'
											className='form-control input-fill'
											placeholder='Email'
											onChange={(e) =>
												setFormValues({
													...formValues,
													from_email: e.target.value,
												})
											}
										/>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-lg-12 col-md-12 col-sm-12'>
									<div className='col-inner-msg'>
										<textarea
											name='message'
											rows='3'
											placeholder='Message'
											className='form-control border-none'
											value={formValues.message}
											onChange={(e) =>
												setFormValues({
													...formValues,
													message: e.target.value,
												})
											}></textarea>
									</div>
								</div>
							</div>
							<div className='row'>
								{formValues.error && (
									<p className='error'>Fill all required fields</p>
								)}
							</div>
							<div className='form-btn'>
								<button type='submit' className='btn btn-primary'>
									{contactFormStatus === 0
										? "Send Now"
										: contactFormStatus === 1
										? "Sending..."
										: "Sent"}
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
			<section className='footer'>
				<Button clickFunc={toggleTermsOfUseModal}>Terms of Use</Button>
				<Button clickFunc={togglePrivacyPolicy}>Privacy Policy</Button>
			</section>
			{termsOfUseModalVisibile && <TermsOfUse />}
			{privacyPolicyModalVisible && <PrivacyPolicy />}
		</>
	);
}

export default LandingPage;
