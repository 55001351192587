import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	meetingFormVisible: false,
	termsOfUseModalVisibile: false,
	privacyPolicyModalVisible: false,
};

export const ui = createSlice({
	name: "ui",
	initialState,
	reducers: {
		toggleMeetingFormVisibility: (state) => ({
			...state,
			meetingFormVisible: !state.meetingFormVisible,
		}),
		toggleTermsOfUseModalVisibility: (state) => ({
			...state,
			termsOfUseModalVisibile: !state.termsOfUseModalVisibile,
		}),
		togglePrivacyPolicyVisibility: (state) => ({
			...state,
			privacyPolicyModalVisible: !state.privacyPolicyModalVisible,
		}),
	},
});

export const {
	toggleMeetingFormVisibility,
	togglePrivacyPolicyVisibility,
	toggleTermsOfUseModalVisibility,
} = ui.actions;
export default ui.reducer;
