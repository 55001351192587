import React, { useState } from "react";
import "./style.scss";
import Button from "../button";

const ConsentModal = ({ onClose = () => {}, title = "", children }) => {
	return (
		<div className='terms-of-use-wrapper' onClick={onClose}>
			<div className='terms-of-use-inner' onClick={(e) => e.stopPropagation()}>
				<div className='terms-of-use-title'>
					<h1>{title}</h1>
					<Button clickFunc={onClose}>X</Button>
				</div>
				<div className='terms-of-use-content'>{children}</div>
			</div>
		</div>
	);
};

export default ConsentModal;
