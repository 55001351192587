import React from "react";
import "./style.scss";
function TermsOfUsePage() {
	return (
		<div className='terms-of-user-page-wrapper'>
			<div className='terms-of-use-title'>
				<h1>Terms of Use Agreement</h1>
			</div>
			<p>
				Welcome to our app. By using our services, you agree to the following
				Terms of Use. Please read them carefully before proceeding.
			</p>

			<h2>1. Use of Zoom Meeting SDK</h2>
			<p>
				This application integrates with Zoom's video conferencing services
				through the Zoom Meeting SDK. By using this app, you acknowledge that
				you are also bound by Zoom's{" "}
				<a
					href='https://explore.zoom.us/en/terms/'
					target='_blank'
					rel='noopener noreferrer'>
					Terms of Service
				</a>
				and{" "}
				<a
					href='https://explore.zoom.us/en/privacy/'
					target='_blank'
					rel='noopener noreferrer'>
					Privacy Policy
				</a>
				. Your use of Zoom's services through our app is subject to their terms,
				including rules regarding video conferencing, data security, and usage
				limits.
			</p>

			<h2>2. Emotion Detection and Data Collection</h2>
			<p>
				Our app uses a third-party AI library to detect and analyze emotions and
				attention levels of meeting participants through their webcams. The
				detected emotions and attention scores are reported to the meeting
				organizer for the purpose of generating participant reports.
			</p>
			<p>
				The app collects and stores the following personal information for each
				participant:
				<ul>
					<li>Name</li>
					<li>Surname</li>
					<li>Email address</li>
				</ul>
				This information, along with the emotions and attention data collected
				during the meeting, may be used to generate reports for the meeting
				owner.
			</p>

			<h2>3. Data Privacy and Security</h2>
			<p>
				We are committed to protecting your privacy and ensuring the security of
				your data. All personal information, including name, surname, email
				address, and emotions data, is stored securely and will not be shared
				with third parties except as necessary for the functioning of the app or
				as required by law.
			</p>
			<p>
				By using this app, you consent to the collection, processing, and
				storage of your personal data as described in this agreement. You also
				acknowledge that the AI library used for emotion detection is provided
				by a third party, and the processing of emotion data may be subject to
				their privacy policies.
			</p>

			<h2>4. Responsibilities and Limitations</h2>
			<p>
				You agree not to misuse our app or the Zoom SDK for purposes other than
				video conferencing and emotion reporting as described in these terms.
				Any unauthorized use of the app or violation of Zoom's terms may result
				in the termination of your access.
			</p>
			<p>
				We make no guarantees regarding the accuracy of the emotion detection or
				attention scores. The reports generated are intended for informational
				purposes only.
			</p>

			<h2>5. Changes to Terms</h2>
			<p>
				We reserve the right to update these Terms of Use at any time. Continued
				use of the app following any changes indicates your acceptance of the
				new terms.
			</p>
			<p className='warning-paragraph'>
				By starting to use the product, you are deemed to have accepted the
				Privacy Policy and Terms of Use Agreement
			</p>
		</div>
	);
}

export default TermsOfUsePage;
