import React, { useState } from "react";
import ConsentModal from "../consent-modal";
import { useDispatch, useSelector } from "react-redux";
import { togglePrivacyPolicyVisibility } from "../../redux/features/ui-slice";

const PrivacyPolicy = ({ onAccept }) => {
	const dispatch = useDispatch();
	const togglePrivacyPolicy = () => dispatch(togglePrivacyPolicyVisibility());

	const handleAccept = () => {
		// setIsAccepted(true);
		// if (onAccept) {
		// 	onAccept();
		// }
		togglePrivacyPolicy();
	};

	return (
		<ConsentModal
			title='Privacy Policy'
			onAccept={handleAccept}
			onClose={togglePrivacyPolicy}>
			<>
				<p>
					<strong>Last updated: August 18, 2024</strong>
				</p>
				<p>
					This Privacy Policy describes Our policies and procedures on the
					collection, use and disclosure of Your information when You use the
					Service and tells You about Your privacy rights and how the law
					protects You.
				</p>
				<p>
					We use Your Personal data to provide and improve the Service. By using
					the Service, You agree to the collection and use of information in
					accordance with this Privacy Policy.
				</p>

				<h2>Interpretation and Definitions</h2>
				<h3>Interpretation</h3>
				<p>
					The words of which the initial letter is capitalized have meanings
					defined under the following conditions. The following definitions
					shall have the same meaning regardless of whether they appear in
					singular or in plural.
				</p>

				<h3>Definitions</h3>
				<ul>
					<li>
						<strong>Account:</strong> means a unique account created for You to
						access our Service or parts of our Service.
					</li>
					<li>
						<strong>Affiliate:</strong> means an entity that controls, is
						controlled by or is under common control with a party.
					</li>
					<li>
						<strong>Company:</strong> (referred to as either "the Company",
						"We", "Us" or "Our") refers to Seenmood.
					</li>
					<li>
						<strong>Cookies:</strong> are small files that are placed on Your
						computer, mobile device or any other device by a website.
					</li>
					<li>
						<strong>Country:</strong> refers to: Turkey.
					</li>
					<li>
						<strong>Device:</strong> means any device that can access the
						Service such as a computer, a cellphone or a digital tablet.
					</li>
					<li>
						<strong>Personal Data:</strong> is any information that relates to
						an identified or identifiable individual.
					</li>
					<li>
						<strong>Service:</strong> refers to the Website.
					</li>
					<li>
						<strong>Service Provider:</strong> means any natural or legal person
						who processes the data on behalf of the Company.
					</li>
					<li>
						<strong>Usage Data:</strong> refers to data collected automatically,
						either generated by the use of the Service or from the Service
						infrastructure itself.
					</li>
					<li>
						<strong>Website:</strong> refers to Seenmood, accessible from{" "}
						<a href='https://www.seenmood.com/'>https://www.seenmood.com/</a>
					</li>
					<li>
						<strong>You:</strong> means the individual accessing or using the
						Service, or the company, or other legal entity on behalf of which
						such individual is accessing or using the Service, as applicable.
					</li>
				</ul>

				<h2>Collecting and Using Your Personal Data</h2>
				<h3>Types of Data Collected</h3>

				<h4>Personal Data</h4>
				<p>
					While using Our Service, We may ask You to provide Us with certain
					personally identifiable information that can be used to contact or
					identify You. Personally identifiable information may include, but is
					not limited to:
				</p>
				<ul>
					<li>Email address</li>
					<li>Usage Data</li>
				</ul>

				<h4>Usage Data</h4>
				<p>
					Usage Data is collected automatically when using the Service. Usage
					Data may include information such as Your Device's IP address, browser
					type, browser version, the pages of our Service that You visit, the
					time and date of Your visit, the time spent on those pages, unique
					device identifiers and other diagnostic data.
				</p>

				<h3>Tracking Technologies and Cookies</h3>
				<p>
					We use Cookies and similar tracking technologies to track the activity
					on Our Service and store certain information. Tracking technologies
					used are beacons, tags, and scripts to collect and track information
					and to improve and analyze Our Service.
				</p>
				<ul>
					<li>
						<strong>Webcam:</strong> Your image is converted into mathematical
						data and collected through the camera on your device.
					</li>
					<li>
						<strong>Cookies or Browser Cookies:</strong> A cookie is a small
						file placed on Your Device. You can instruct Your browser to refuse
						all Cookies or to indicate when a Cookie is being sent. However, if
						You do not accept Cookies, You may not be able to use some parts of
						our Service.
					</li>
				</ul>

				<h3>Use of Your Personal Data</h3>
				<p>The Company may use Personal Data for the following purposes:</p>
				<ul>
					<li>
						To provide and maintain our Service, including to monitor the usage
						of our Service.
					</li>
					<li>
						To manage Your Account: to manage Your registration as a user of the
						Service.
					</li>
					<li>
						To contact You by email, telephone calls, SMS, or other equivalent
						forms of electronic communication.
					</li>
				</ul>

				<h3>Retention of Your Personal Data</h3>
				<p>
					The Company will retain Your Personal Data only for as long as is
					necessary for the purposes set out in this Privacy Policy.
				</p>

				<h3>Transfer of Your Personal Data</h3>
				<p>
					Your information may be transferred to and maintained on computers
					located outside of Your state or country where the data protection
					laws differ.
				</p>

				<h3>Delete Your Personal Data</h3>
				<p>
					You have the right to delete or request that We assist in deleting the
					Personal Data that We have collected about You.
				</p>

				<h3>Disclosure of Your Personal Data</h3>
				<p>
					If the Company is involved in a merger, acquisition or asset sale,
					Your Personal Data may be transferred. We will provide notice before
					Your Personal Data is transferred and becomes subject to a different
					Privacy Policy.
				</p>

				<h3>Security of Your Personal Data</h3>
				<p>
					The security of Your Personal Data is important to Us, but no method
					of transmission over the Internet is 100% secure.
				</p>

				<h3>Children's Privacy</h3>
				<p>
					Our website is intended for users of all ages, but users under the age
					of 18 must obtain permission from a parent or guardian.
				</p>

				<h3>Changes to this Privacy Policy</h3>
				<p>
					We may update Our Privacy Policy from time to time. Changes to this
					Privacy Policy are effective when they are posted on this page.
				</p>

				<h3>Contact Us</h3>
				<p>
					If you have any questions about this Privacy Policy, You can contact
					us by email at: info@seenmood.com
				</p>
			</>
		</ConsentModal>
	);
};

export default PrivacyPolicy;
